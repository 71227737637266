.help-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.help-button {
  padding: 0; /* Remove padding to better fit the image */
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 3px solid transparent;
  border-radius: 50%;
  padding: 5px;
  position: relative; /* Position for tooltip */
}

.help-button:hover {
  background-color: rgba(80, 220, 255, 0.1725490196);
}

/* Style the image within the button */
.help-icon {
  width: 40px; /* Adjust the size as needed */
  height: 40px;
}

/* Tooltip styling */
.tooltip {
  position: absolute;
  background: #ffffff;
  color: #000;
  padding: 8px;
  border-radius: 4px;
  bottom: calc(100% + 5px); /* Positioning the tooltip above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  animation: tooltipAnimation 4s infinite; /* Animation for appearing and disappearing */
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

/* Keyframe animation */
@keyframes tooltipAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -20px);
  }
  50% {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -20px);
  }
}
/* Style adjustments on hover */
.help-button span,
.help-button .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}/*# sourceMappingURL=helpbtn.css.map */