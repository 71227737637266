@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");
header {
  position: relative;
  filter: drop-shadow(0px -60px 60px #000b2b);
  top: 0px;
  z-index: 4;
  transition: all 0.3s ease-in-out;
}
header.openmenu {
  position: sticky;
}
header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.wnbtn {
  border: none;
  color: #fff;
  background-color: #bc4242;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 4px;
  display: block;
}

.logo img {
  width: 100%;
  max-width: 85px;
  padding: 15px 15px 15px 0px;
}

.menu {
  position: relative;
  top: 0px;
}
.menu li {
  display: inline-block;
  margin: 0px 12px;
}
.menu a {
  display: block;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: 1.5px;
  font-weight: 400;
  cursor: pointer;
}

.balancebox {
  display: flex;
  align-items: center;
}
.balancebox li {
  display: inline-block;
}
.balancebox li:nth-last-child(1) {
  margin-left: 10px;
}
.balancebox li span {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 2px;
  position: relative;
  min-width: auto;
  margin: 0px;
}
.balancebox li span::before {
  width: 100px !important;
}
.balancebox li span::after {
  width: 100px !important;
}
.balancebox li strong {
  margin-left: 8px;
}

.rightboxmenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  bottom: 0px;
}

.hamburger {
  cursor: pointer;
  width: 35px !important;
  height: 26px;
  position: relative;
  margin-left: 15px;
  z-index: 1;
}
.hamburger span {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  background-color: #02ffff;
  display: inline-block;
  content: "";
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 10px;
  left: 0px;
  width: 100%;
}
.hamburger span:before {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 100%;
  position: absolute;
  display: inline-block;
  content: "";
  top: -10px;
  left: 0px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 100%;
  position: absolute;
  display: inline-block;
  content: "";
  bottom: -10px;
  left: 0px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger.is-active span {
  background-color: transparent;
}
.hamburger.is-active span:before {
  top: 0;
  transform: rotate(45deg);
}
.hamburger.is-active span:after {
  top: 0;
  transform: translateY(-10px) rotate(-45deg);
  top: 10px;
}

.menubar {
  position: fixed;
  top: 0;
  left: -360px;
  width: 0px;
  height: 100%;
  z-index: 3;
  background-color: #041516;
  display: block !important;
  box-shadow: -20px 0px 30px 0px #000000;
  transition: all 0.3s ease-in-out;
  padding-top: 140px;
  padding-left: 20px;
}
.menubar .menu {
  display: block;
  padding-top: 30px !important;
}
.menubar .menu li {
  display: block;
  margin-bottom: 10px;
}
.menubar .menu li a {
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 20px;
}

@media only screen and (max-width: 1840px) {
  .menu li {
    margin: 0px 10px;
  }
}
@media only screen and (max-width: 1440px) {
  .menu li {
    margin: 0px 10px;
  }
  .menu a {
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 500;
  }
  header .clipHeader {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 0 100%, 0 0);
  }
  header .clipHeader::before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 10px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 10px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
  }
  header .before {
    width: 300px;
  }
}
@media only screen and (max-width: 992px) {
  .balancebox li span {
    padding: 10px 15px;
    letter-spacing: 1px;
    margin: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .logo img {
    max-width: 60px;
  }
  header .clipHeader {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0 100%, 0 0);
  }
  header .clipHeader::before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
  }
  .rightboxmenu {
    bottom: 0px;
  }
  header::before {
    height: 120px;
  }
  .buttonGroup2 .btn1,
  .buttonGroup2 .btn2,
  .buttonGroup2 .btn3 {
    min-height: 76px;
  }
}
@media only screen and (max-width: 992px) {
  .menu li {
    margin: 0px 9px;
  }
}
@media only screen and (max-width: 390px) {
  .balancebox {
    font-size: 14px;
  }
  .balancebox li span {
    padding: 8px 10px;
  }
  .hamburger {
    width: 30px !important;
    height: 23px;
    margin-left: 10px;
  }
}/*# sourceMappingURL=header.css.map */