*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body .MuiTabs-flexContainer {
  justify-content: flex-start;
}

body .MuiPickersLayout-contentWrapper .MuiDateRangeCalendar-root > div:nth-child(1) {
  opacity: 0;
  visibility: hidden;
}

.adminReportTable {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: auto;
}

.adminReportTable2 {
  margin-bottom: 40px;
  overflow: hidden;
}

.adminReportTable .os_container {
  max-width: 800px;
}
.adminReportTable .react-tabs .react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.adminReportTable .react-tabs .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  padding: 15px;
  text-align: center;
}
.adminReportTable .table-responsive {
  overflow: auto;
}
.adminReportTable .table-responsive .text-right {
  text-align: right;
}
.adminReportTable .table-responsive .thead .tr {
  background-color: #680d48;
}
.adminReportTable .table-responsive .thead .tr .th {
  padding: 15px;
}
.adminReportTable .table-responsive .tbody .tr .td {
  border-bottom: 1px solid #323232;
  padding: 15px 0px;
  margin: 0;
  width: 100%;
}
.adminReportTable .table-responsive .tr .td {
  padding: 10px;
}
.adminReportTable .table-responsive .table {
  width: 100%;
}

.os_container_admin {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.boxsmain2 {
  margin-top: 50px;
}
.boxsmain2 .os_container {
  max-width: 1320px;
}
.boxsmain2 .os_row {
  justify-content: center;
}
.boxsmain2 .col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 20px;
}

.boxsmaint {
  margin-top: 50px;
}
.boxsmaint .os_container {
  max-width: 1320px;
}

.boxsmain {
  margin-top: 50px;
}
.boxsmain .os_container {
  max-width: 800px;
  margin: 0 auto;
}
.boxsmain .os_row {
  justify-content: center;
}
.boxsmain .col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
}
.boxsmain .cardbox {
  background: linear-gradient(to right, rgba(98, 74, 153, 0.24), #000000);
  border: 1px solid rgba(78, 42, 42, 0.5058823529);
  padding: 20px 15px;
  border-radius: 7px;
  position: relative;
  text-align: center;
  height: 100%;
}
.boxsmain .cardbox h2 {
  margin: 0 0 8px;
  font-weight: 500;
}
.boxsmain .cardbox p {
  margin: 0px;
  font-size: 16px;
}
.boxsmain .cardbox::after {
  content: "";
  position: absolute;
  top: -14px;
  right: -9px;
  background-color: #000;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-left: 1px solid rgba(78, 42, 42, 0.5058823529);
}
.boxsmain .cardbox::before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: -9px;
  background-color: #000;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-right: 1px solid rgba(78, 42, 42, 0.5058823529);
}
.boxsmain .titleh2 {
  text-align: center;
}
.boxsmain .titleh2 h2 {
  text-align: center;
  margin: 0 0 15px;
  font-size: 42px;
  font-weight: 500;
  font-family: "techmont-bold", sans-serif;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
  color: #ffffff;
}

.os_row_ad {
  display: flex;
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.adminReportTable .os_container {
  max-width: 800px;
}
.adminReportTable .react-tabs .react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.adminReportTable .react-tabs .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  padding: 15px;
  text-align: center;
}
.adminReportTable .table-responsive {
  overflow: auto;
}
.adminReportTable .table-responsive .text-right {
  text-align: right;
}
.adminReportTable .table-responsive .thead .tr {
  background-color: #680d48;
}
.adminReportTable .table-responsive .thead .tr .th {
  padding: 15px;
}
.adminReportTable .table-responsive .tbody .tr .td {
  border-bottom: 1px solid #323232;
  padding: 15px 0px;
  margin: 0;
  width: 100%;
}
.adminReportTable .table-responsive .tr .td {
  padding: 10px;
}
.adminReportTable .table-responsive .table {
  width: 100%;
}

header .os_col {
  flex: 1;
  justify-content: space-between;
}
header .titles {
  font-size: 30px;
  text-align: center;
}
header .wallet {
  text-align: right;
  display: flex;
}
header .wallet button {
  margin-left: 20px;
  min-width: 100px;
  font-size: 16px;
  padding: 10px;
  border-radius: 4px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.3411764706);
  cursor: pointer;
  border: 1px solid #0c041b;
  transition: all 0.2s ease-in-out;
  position: relative;
}
header .wallet img {
  width: 40px;
  height: auto;
  filter: invert(1);
  cursor: pointer;
}

.mainbox_ad {
  /* Firefox */
}
.mainbox_ad .title {
  text-align: center;
  margin: 0 0 15px;
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
  color: #ffa902;
}
.mainbox_ad input::-webkit-outer-spin-button,
.mainbox_ad input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mainbox_ad input[type=number] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}

#root {
  min-height: 100vh;
}

.mainbox_ad {
  margin-top: 20px;
  margin-bottom: 30px;
}

.boxmain_ad {
  position: relative;
  text-align: center;
  top: 50px;
}
.boxmain_ad .swapbtn {
  margin: 4px auto;
  text-align: center;
  position: relative;
  z-index: 2;
  background-color: rgb(236, 72, 153);
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: auto;
  transform: scale(4);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxmain_ad .swapbtn img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 6px;
  filter: invert(1);
}

.approvebtn {
  padding: 0;
  width: 50%;
  height: 60px;
  font-family: "defiworld-Regular";
  border-radius: 5px;
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  margin: 0 auto;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  margin-top: 25px;
  color: #ffffff;
  font-size: 22px;
}
.approvebtn.mt-25 {
  margin-top: 25px;
}
.approvebtn img {
  height: 14px;
  width: auto;
  position: relative;
  left: 8px;
  filter: invert(1);
}
.approvebtn span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 0px;
}
.approvebtn span::before, .approvebtn span::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: hsl(199.38, 71.03%, 56.51%);
  transition: all 0.3s ease;
}
.approvebtn span::before {
  width: 2px;
  height: 0%;
}
.approvebtn span::after {
  width: 0%;
  height: 2px;
}
.approvebtn span:hover::before {
  height: 100%;
}
.approvebtn span:hover::after {
  width: 100%;
}
.approvebtn::before, .approvebtn::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: hsl(199.38, 71.03%, 56.51%);
  transition: all 0.3s ease;
}
.approvebtn::before {
  height: 0%;
  width: 2px;
}
.approvebtn::after {
  width: 0%;
  height: 2px;
}
.approvebtn:hover {
  background: transparent;
  color: hsl(199.38, 71.03%, 56.51%);
}
.approvebtn:hover::before {
  height: 100%;
}
.approvebtn:hover::after {
  width: 100%;
}

.boxinner_ad {
  background: #0c171a;
  padding: 10px 20px 30px;
  border-radius: 7px;
  position: relative;
  text-align: left;
  border: 1px solid #426a9b;
}
.boxinner_ad::before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: -9px;
  background-color: rgb(9, 9, 36);
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-right: 1px solid #426a9b;
}
.boxinner_ad::after {
  content: "";
  position: absolute;
  top: -14px;
  right: -9px;
  background-color: rgb(9, 9, 36);
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-left: 1px solid #426a9b;
}
.boxinner_ad p {
  margin: 0px;
  text-transform: capitalize;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
}
.boxinner_ad strong {
  margin: 0px;
  text-transform: capitalize;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
}
.boxinner_ad strong span {
  color: #ffffff;
}
.boxinner_ad .boxpart_ad {
  margin-top: 20px;
}
.boxinner_ad .leftbx_ad {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.boxinner_ad .leftbx_ad img {
  display: block;
  width: 30px;
  margin-right: 10px;
  max-width: 30px;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 3px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.boxinner_ad .leftbx_ad strong {
  font-weight: 500;
  color: #ffffff;
}
.boxinner_ad .leftbx_ad .btnaddtkn {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 7px;
  color: #ffe6bf;
  font-size: 14px;
  text-decoration: none;
}
.boxinner_ad .leftbx_ad .btnaddtkn:hover {
  text-decoration: underline;
}
.boxinner_ad .leftbx_ad p {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 12px;
  font-size: 14px;
}
.boxinner_ad .leftbx_ad p span {
  color: #ffffff;
}
.boxinner_ad input {
  flex: 1;
  margin-left: 0px;
  border: 1px solid #426a9b;
  padding: 10px 12px;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  color: #ffffff;
  text-align: left;
  width: 100%;
  font-size: 16px;
}

.overlaypopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.83);
  z-index: 3;
}

.ospopup {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(245, 246, 252);
  box-shadow: rgba(51, 53, 72, 0.04) 8px 12px 20px, rgba(51, 53, 72, 0.02) 4px 6px 12px, rgba(51, 53, 72, 0.04) 4px 4px 8px;
  padding: 0px;
  width: 90%;
  overflow: hidden auto;
  max-width: 420px;
  max-height: 90vh;
  border-radius: 20px;
  color: #222222;
  transform: translate(-50%, -50%);
  z-index: 4;
}
.ospopup .popuptitle {
  padding: 15px 15px 20px;
}
.ospopup .popuptitle h3 {
  margin: 0px;
  font-size: 16px;
}
.ospopup .popuptitle button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  position: relative;
  border: none;
  cursor: pointer;
}
.ospopup .popuptitle button svg {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
}
.ospopup .popuptitle button::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
}

.popupbtngrp {
  padding: 0px 7px 15px;
}
.popupbtngrp button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #e8ecfb;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  color: #0d111c;
  font-size: 16px;
  font-weight: 600;
  flex: 0 0 calc(50% - 14px);
  max-width: calc(50% - 14px);
  margin-left: 7px;
  margin-right: 7px;
}
.popupbtngrp button img {
  height: 28px;
  width: 28px;
  margin-right: 12px;
}

.osprivacy {
  padding: 10px 15px 15px;
  margin-bottom: 10px;
  color: #7780a0;
}
.osprivacy a {
  text-decoration: none;
  cursor: pointer;
  color: #fb118e;
  font-weight: 500;
}

.confirmingtransaction {
  text-align: center;
}
.confirmingtransaction .loaderimg {
  width: 100%;
  max-width: 60px;
}
.confirmingtransaction h2 {
  margin: 20px 0 0;
  font-size: 26px;
  font-weight: 500;
}
.confirmingtransaction .approvebtn {
  max-width: calc(100% - 40px);
  margin: 30px auto 20px;
  border: none;
}

.boxsmain_ad {
  margin-top: 50px;
}
.boxsmain_ad .os_container {
  max-width: 1320px;
}
.boxsmain_ad .os_row {
  justify-content: center;
}
.boxsmain_ad .col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
}
.boxsmain_ad .cardbox {
  padding: 20px 15px;
  border-radius: 7px;
  position: relative;
  text-align: center;
  height: 100%;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  /* background: transparent; */
}
.boxsmain_ad .cardbox .iconbox {
  max-width: 100px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  padding: 0;
  margin-bottom: 15px;
}
.boxsmain_ad .cardbox .iconbox img {
  width: 100%;
  height: auto;
  animation: move 2s linear infinite;
}
.boxsmain_ad .cardbox h2 {
  margin: 0 0 8px;
  font-size: 20px;
  font-weight: 600;
}
.boxsmain_ad .cardbox p {
  margin: 0px;
  font-size: 16px;
}
@keyframes move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.boxsmain_ad .titleh2 {
  text-align: center;
}
.boxsmain_ad .titleh2 h2 {
  font-family: "Poppins";
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0 0 10px;
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 15px;
}

.inputbox_ad {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0;
  margin: 0;
}
.inputbox_ad button {
  margin-left: 20px;
  min-width: 100px;
  font-size: 16px;
  padding: 10px;
  border-radius: 4px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  background-color: hsl(199.38, 71.03%, 56.51%);
  cursor: pointer;
  border: 1px solid #426a9b;
  transition: all 0.2s ease-in-out;
  position: relative;
}
.inputbox_ad button::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: -4px;
  background-color: #101a2c;
  transform: rotate(-45deg);
  width: 13px;
  height: 21px;
  border-right: 1px solid #0c041b;
  transition: all 0.2s ease-in-out;
}
.inputbox_ad button::after {
  content: "";
  position: absolute;
  top: -8px;
  right: -4px;
  background-color: #101a2c;
  transform: rotate(-45deg);
  width: 13px;
  height: 21px;
  border-left: 1px solid #0c041b;
  transition: all 0.2s ease-in-out;
}
.inputbox_ad button:hover {
  border: 1px solid #c9c9c9;
}
.inputbox_ad button:hover::before, .inputbox_ad button:hover::after {
  border-color: #c9c9c9;
}

.leftbx_ad {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 5px;
}

form {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
}

input {
  text-align: left;
  margin-left: 0px;
}

.Emergencydrop {
  border: 1px solid #426a9b;
  width: 100%;
  background: rgba(10, 53, 78, 0.968627451);
  color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.content--canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: hue-rotate(-252deg);
  opacity: 0.5;
}

.wrapper {
  min-height: 100vh;
}

.adminReportTable .os_container_admin {
  max-width: 1320px;
}
.adminReportTable .react-tabs .react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.adminReportTable .react-tabs .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  padding: 15px;
  text-align: center;
  background: rgb(246, 178, 51);
  font-size: 20px;
  font-weight: 600;
}
.adminReportTable body .table-responsive .text-right {
  text-align: right;
}
.adminReportTable body .table-responsive thead tr {
  background-color: #23163a;
}
.adminReportTable body .table-responsive thead tr th {
  padding: 15px;
  text-align: center;
  font-size: 15px;
  border-bottom: 1px solid hsl(199.38, 71.03%, 56.51%);
}
.adminReportTable body .table-responsive tbody tr td {
  border-bottom: 1px solid #323232;
  padding: 10px;
  margin: 0;
  text-align: center;
  background: black;
  color: rgba(255, 255, 255, 0.9215686275);
}
.adminReportTable body .table-responsive tr td {
  padding: 10px;
}
.adminReportTable body .table-responsive table {
  width: 100%;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.adminReportTable .os_container {
  max-width: 1320px;
  margin: 0 auto;
}
.adminReportTable .react-tabs .react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.adminReportTable .react-tabs .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  padding: 15px;
  text-align: center;
  background: linear-gradient(rgb(0, 61, 114) 0%, rgb(59, 143, 212) 70.45%);
  font-size: 20px;
  font-weight: 600;
}
.adminReportTable body .table-responsive .text-right {
  text-align: right;
}
.adminReportTable body .table-responsive thead tr {
  background-color: #23163a;
}
.adminReportTable body .table-responsive thead tr th {
  padding: 15px;
  text-align: center;
  font-size: 15px;
}
.adminReportTable body .table-responsive tbody tr td {
  border-bottom: 1px solid #323232;
  padding: 10px;
  margin: 0;
  text-align: center;
  background: black;
  color: rgba(255, 255, 255, 0.9215686275);
}
.adminReportTable body .table-responsive tr td {
  padding: 10px;
}
.adminReportTable body .table-responsive table {
  width: 100%;
}

.justify-content-between {
  justify-content: space-between !important;
}

.form {
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: var(--width-of-input);
  position: relative;
  isolation: isolate;
  margin: 0 auto;
}

.form {
  --input-text-color: #fff;
  --input-bg-color: #283542;
  --focus-input-bg-color: transparent;
  --text-color: #949faa;
  --active-color: #fdc06c;
  --width-of-input: 200px;
  --inline-padding-of-input: 1.2em;
  --gap: 0.9rem;
}

/* label styling */
label {
  width: 100%;
  padding: 0.8em;
  height: 40px;
  padding-inline: var(--inline-padding-of-input);
  display: flex;
  align-items: center;
}
label .input {
  color: var(--input-text-color);
  width: 100%;
  margin-inline: min(2em, var(--inline-padding-of-input) + var(--gap));
  background: none;
  border: none;
}

.search,
.close-btn {
  position: absolute;
}

/*DATE PICKER */
.abcde {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.abcde .MuiSvgIcon-fontSizeMedium {
  position: absolute;
  top: 40%;
  right: 92px;
  transform: translateY(-50%);
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiStack-root.MuiMultiInputDateRangeField-root.css-hw8s2-MuiStack-root-MuiMultiInputDateRangeField-root {
  width: 500px !important;
  margin: 0 auto;
}

body .MuiFormControl-root-MuiTextField-root {
  border: 1px solid !important;
}

body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  max-width: 500px !important;
}

body .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  border: 1px solid #9b4242 !important;
}

body .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  margin: 0 auto;
  margin-bottom: 20px;
}

body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid hsla(0, 71.03%, 56.51%, 0.58) !important;
}

body .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff;
}

body .MuiOutlinedInput-input {
  text-align: center !important;
}

/* styling search-icon */
.search {
  fill: var(--text-color);
  left: var(--inline-padding-of-input);
}

svg {
  width: 17px;
  display: block;
}

.fancy-bg {
  position: absolute;
  width: 100%;
  inset: 0;
  background: var(--input-bg-color);
  border-radius: 30px;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.input:valid ~ .close-btn {
  opacity: 1;
  visibility: visible;
}

.close-btn {
  border: none;
  right: var(--inline-padding-of-input);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: hsl(0, 71.03%, 56.51%);
  opacity: 0;
  visibility: hidden;
}

body .MuiBox-root {
  background-color: transparent;
}

.css-1azz2d9 {
  width: 100%;
  background-color: #000;
  margin-bottom: 50px;
  margin-top: 30px;
}

.css-tkn350-MuiTabs-root {
  overflow: hidden;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.css-69z67c-MuiTabs-scroller {
  position: relative;
  display: inline-block;
  flex: 1 1 auto;
  white-space: nowrap;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: hidden;
}

.hello .MuiTabs-flexContainer {
  justify-content: center;
}

.css-heg063-MuiTabs-flexContainer {
  display: flex;
}

.MuiTabs-flexContainer {
  display: flex;
}

.MuiTabs-flexContainer {
  justify-content: space-between;
}

.MuiTabs-scroller .MuiTabs-flexContainer button.Mui-selected {
  background: transparent;
  font-weight: 600;
  background-size: 200%;
  border: 1px solid hsla(352, 88%, 47%, 0.548) !important;
}

.css-tkn350-MuiTabs-root {
  overflow: hidden;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.MuiTabs-scroller .MuiTabs-flexContainer button {
  color: #fff;
  border: 1px solid hsla(352, 74%, 56%, 0.582) !important;
  margin: 0 10px;
  border-radius: 6px;
  font-size: 16px;
  min-width: 100px;
  background-color: transparent;
}

.css-8je8zh-MuiTouchRipple-root {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  opacity: 0;
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  width: 40px;
  flex-shrink: 0;
  opacity: 0.8;
}

.css-ptiqhd-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  width: 40px;
  flex-shrink: 0;
  opacity: 0.8;
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  width: 40px;
  flex-shrink: 0;
  opacity: 0.8;
}

.prevbtn {
  display: flex;
  /* display: block; */
  /* justify-content: center; */
  /* text-align: center; */
  justify-content: space-between;
  margin-top: 10px;
}
.prevbtn button {
  background: transparent;
  color: #fff;
  border: 1px solid hsl(0, 71%, 56%);
}

.btnexcel {
  margin-top: 15px !important;
  margin-left: auto !important;
  display: block !important;
}

.inputswapbtn {
  word-wrap: normal;
  position: absolute;
  left: 240px;
  margin-top: 12px;
  border-radius: 10%;
  border: 1px solid #707070;
  background-color: rgba(78, 10, 10, 0.5411764706);
  color: #d7cfcf;
}

@media only screen and (max-width: 768px) {
  .boxsmain .col4 {
    padding: 8px;
  }
  .boxsmain .cardbox {
    padding: 15px 8px;
  }
  .boxsmain .cardbox p {
    font-size: 16px;
    min-height: 35px;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 575px) {
  .os_container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .approvebtn {
    font-size: 15px;
  }
  .boxinner_ad {
    padding: 25px 10px 60px;
  }
  .popupbtngrp {
    padding: 0px 15px;
  }
  .popupbtngrp button {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .boxsmain .col4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }
  .MuiTabs-scroller .MuiTabs-flexContainer button {
    font-size: 14px;
    min-width: 80px;
    background-color: transparent;
  }
  .adminform_ad .boxinner_ad .inputbox_ad button {
    margin-left: 15px;
    min-width: 73px;
    font-size: 14px;
    padding: 10px;
    text-transform: capitalize;
  }
  .adminform_ad .boxinner_ad input {
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
  .inputbox_ad button {
    margin-left: 15px;
    min-width: 73px;
    font-size: 14px;
    padding: 10px;
    text-transform: capitalize;
  }
  .inputswapbtn {
    word-wrap: normal;
    margin-top: 0px;
    border-radius: 10%;
    background-color: #2c2b2d;
    color: #fff;
    font-size: 15px;
    margin-top: 6px;
    margin-bottom: 6px;
    position: static;
  }
  .MuiTabs-scroller .MuiTabs-flexContainer {
    -moz-column-gap: 0px;
         column-gap: 0px;
  }
}/*# sourceMappingURL=Admreport.css.map */