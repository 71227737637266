.footer {
  border: 1px solid transparent !important;
  background-color: transparent;
  align-items: center;
  display: flex;
  min-height: 48px;
  flex-wrap: wrap;
  padding: 16px;
  position: relative;
  width: 100%;
  bottom: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.footer_inner_row {
  text-align: center;
}

.footer_seperator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  position: relative;
}

.footer-seperator-line {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}

.footer_copyright_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.footer_copyright_text {
  text-align: center;
  font-size: 0.875rem;
  color: #fff;
}

.community_icon_wrapper {
  display: flex;
  gap: 1rem;
}

.icon-holder {
  display: inline-block;
}

.icon_wrapper img {
  width: 24px;
  height: auto;
  filter: invert(1);
}

.footer_inner_row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.footer_copyright_wrapper {
  font-family: "techmont-bold", sans-serif;
}

.footer-seperator-line {
  background-image: linear-gradient(30deg, #000, #8d0000 50%, #000);
  height: 1.5px;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-top: 0;
  width: 100%;
}

.footer_copyright_text {
  color: #d60d0d;
}

.community_icon_wrapper {
  grid-column-gap: 20px;
  grid-row-gap: 32px;
  align-items: center;
  display: -ms-grid;
  display: flex;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}
.community_icon_wrapper svg {
  vertical-align: middle;
  height: 28px;
  width: 28px;
}

:not(svg) {
  transform-origin: 0px 0px;
}

.footer-cta {
  background-image: linear-gradient(to right, rgba(229, 14, 42, 0), #e50e2a 65%);
  color: #fff;
  -webkit-text-stroke-width: 1px;
  width: 100%;
  height: 9.13rem;
  font-family: "techmont-bold", sans-serif;
  font-size: 76px;
  font-weight: 700;
  line-height: 76px;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.icon-holder {
  width: 44px;
  height: 44px;
  background-color: rgba(184, 7, 7, 0.2);
  border: 1px solid #b80707;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  display: flex;
  transform: rotate(45deg);
}

.icon-holder:hover {
  box-shadow: 0px 6px 30px rgba(255, 11, 11, 0.9647058824);
}

.ftr-icon {
  transform: rotateZ(-45deg);
  transform-origin: center;
}

@media only screen and (max-width: 991px) {
  .footer-cta {
    height: 7rem;
  }
}
/* Responsive Design */
@media (min-width: 650px) {
  .footer_copyright_wrapper {
    justify-content: space-between;
  }
  .community_icon_wrapper {
    padding-bottom: 1rem;
    margin-left: 0;
  }
  .footer_copyright_text {
    text-align: left;
  }
}
@media only screen and (max-width: 650px) {
  .community_icon_wrapper {
    margin-left: 45px;
  }
}
@media only screen and (max-width: 554px) {
  .footer_copyright_wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  .footer-cta {
    height: 5.5rem;
  }
}
@media only screen and (max-width: 400px) {
  .footer_copyright_wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}/*# sourceMappingURL=Footer.css.map */